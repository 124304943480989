import * as React from "react";
import { Box, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import Dropdown from "./DropDown";
import TextInput from "./TextInput";
import CustomTextarea from "./CustomTextarea";
import CheckboxGroup from "./CheckboxGroup";
import RadioButton from "./RadioButton";
import NumericInput from "./NumericInput";
import CustomUploadFile from "./CustomUploadFile";
import CustomDatePicker from "./CustomDatePicker";
import FormFiledButton from "./FormFiledsButton";

interface FormField {
  trigger: any;
  order: string;
  attributeType: number;
  attributeName: string;
  attributeDescription: string;
}
interface TriggerType {
  id: number;
  type: string;
  value: string;
  compareId: string;
}

export const renderFormFields = (
  item: FormField,
  i: number,
  handleChange: any,
  values: any,
  errors: Record<string, any>,
  getSelections: (order: string) => any[],
  documentTagTypeonChange?: string
) => {
  const handleselectionvalue = (value: any, fieldName: string) => {
    handleChange(fieldName, value);
  };

  const shouldFieldBeVisible = (trigger: TriggerType | null) => {
    if (!trigger) return true;
    const currentValues = values[trigger.compareId];
    const selectedvalues = item.trigger?.value.split(",");
    return selectedvalues.includes(currentValues);
  };

  if (item.trigger && !shouldFieldBeVisible(item.trigger)) {
    return null;
  }

  switch (+item.attributeType) {
    case 1: // Textbox
      return (
        <Box key={item.order} marginBottom={2}>
          <Typography variant="subtitle2" align="left" gutterBottom>
            {item.attributeDescription}
          </Typography>
          <Field
            name={item.order}
            label={item.attributeName}
            component={TextInput}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 2: //Description box
      return (
        <Box key={item.order} marginBottom={2}>
          <Typography variant="subtitle2" align="left" gutterBottom>
            {item.attributeName}
          </Typography>
          <Field
            name={item.order}
            label={item.attributeDescription}
            component={CustomTextarea}
          />
          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );

    case 3: //checkbox
      return (
        <Box key={item.order} marginBottom={2}>
          <Typography variant="subtitle2" align="left" gutterBottom>
            {item.attributeName}
          </Typography>
          <Field
            name={item.order}
            label={item.attributeDescription}
            options={getSelections(item.order)}
            component={CheckboxGroup}
          />
          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 4: //radio button
      return (
        <Box key={item.order} marginBottom={2}>
          <Field
            name={item.order}
            label={item.attributeDescription}
            options={getSelections(item.order)}
            component={RadioButton}
          />
          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 5: // UploadButton
      return (
        <Box key={item.order} marginBottom={2} flexDirection={"row"}>
          <Typography variant="caption" align="left">
            {item.attributeName}
          </Typography>
          <Field
            key={item.order}
            name={item.order}
            label={item.attributeDescription}
            component={CustomUploadFile}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 6: //numeric box
      return (
        <Box key={item.order} marginBottom={2}>
          <Typography variant="subtitle2" align="left" gutterBottom>
            {item.attributeName}
          </Typography>
          <Field
            name={item.order}
            label={item.attributeDescription}
            component={NumericInput}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 8: //dropdown
      return (
        <Box key={item.order} marginBottom={2} flexDirection={"row"}>
          <Typography variant="caption" align="left">
            {item.attributeName}
          </Typography>
          <Field
            key={item.order}
            name={item.order}
            label={item.attributeDescription}
            options={getSelections(item.order)}
            component={Dropdown}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    case 9: //date picker
      return (
        <Box key={item.order} marginBottom={2} flexDirection={"column"}>
          <Typography variant="caption" align="left">
            {item.attributeName}
          </Typography>
          <br></br>
          <Field
            key={item.order}
            name={item.order}
            label={item.attributeDescription}
            component={CustomDatePicker}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );

    case 10: // Single Selection Button
      return (
        <Box key={item.order} marginBottom={2} flexDirection={"row"}>
          <Field
            key={item.order}
            name={item.order}
            label={item.attributeDescription}
            options={getSelections(item.order)}
            component={FormFiledButton}
            onChange={(value: any) => {
              handleselectionvalue(value, item.attributeName);
            }}
          />

          {errors[item.order] && (
            <Typography variant="caption" color="error">
              <ErrorMessage name={item.order} />
            </Typography>
          )}
        </Box>
      );
    default:
      return null;
  }
};
