import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyle } from "./components/common/styles/global";
import Main from "./Main";

const bodyStyle = {
  height: "100vh",
  margin: "auto",
};

const webkitStyles = `
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d5d7db;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

function App() {
  return (
    <div className="App">
      <style>{webkitStyles}</style>
      <div style={bodyStyle}>
        <Main />
      </div>
    </div>
  );
}

export default App;
